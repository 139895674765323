/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useLocalStorage } from 'react-use';
import {
  ArrowSquareOut,
  CaretDown,
  CheckCircle,
  DotsThreeOutlineVertical,
  Download,
  Plus,
  FolderPlus,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { Button, DropdownOption } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import AddToCaseModal from '../../../components/common/AddToCaseModal';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';
import AddCustomerModal from '../../../components/Customer/CustomerList/AddCustomerModal';
import CustomerListExportModal from '../../../components/Customer/CustomerList/CustomerListExportModal';
import CustomerListFilter, {
  IFilters,
  defaultCustomerListFilters,
} from '../../../components/Customer/CustomerList/CustomerListFilter';
import CustomerListAppliedFilter from '../../../components/Customer/CustomerList/CustomerListAppliedFilter';
import SimpleDropdown from '../../../components/ui/components/Dropdown/SimpleDropdown';
import Tab from '../../../../src/components/ui/components/Tab/Tab';

import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { useWorkspaceAssignmentCountGet } from '../../../api/workflows';
import { useStagesListLiteGet } from '../../../api/stages';
import { customerApi, useCustomerGetCustomerList } from '../../../api/customer';

import { IStageListLite } from '../../../api/dtos/stages';
import { ICustomerResponse } from '../../../api/dtos/customer';
import { IGetWorkspaceAssignmentCountsResponse } from '../../../api/dtos/workflows';
import { useWorkspace } from '../../../utils/helpers/common';
import { useGetCustomerData } from './CustomerData';
import { isEqual } from 'lodash';

const tabData = ['assigned_to_me', 'unassigned', 'closed', 'all_customers'];

const CustomerList: React.FC = () => {
  const { state } = useAuth();
  const { getQueryParams, navigate } = useRouter();
  const {
    deposit_address,
    risk_level,
    currencies,
    created_start_date,
    created_end_date,
    start_date,
    end_date,
    is_included_in_case,
    rule_name,
    workflow_stage,
    tab,
  } = getQueryParams();
  const workspace = useWorkspace();
  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.customerFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = React.useState<IFilters>({
    deposit_address: deposit_address && deposit_address !== 'null' ? deposit_address : null,
    risk_level: risk_level ? risk_level.split(',').map(Number) : [],
    currencies: currencies ? currencies.split(',').map(Number) : [],
    created_start_date: created_start_date && created_start_date !== 'null' ? created_start_date : null,
    created_end_date: created_end_date && created_end_date !== 'null' ? created_end_date : null,
    start_date: start_date && start_date !== 'null' ? start_date : null,
    end_date: end_date && end_date !== 'null' ? end_date : null,
    is_included_in_case:
      is_included_in_case === null || is_included_in_case === undefined ? null : Boolean(is_included_in_case),
    rule_name: rule_name ? rule_name.split(',') : [],
    workflow_stage: workflow_stage
      ? workflow_stage.split(',').map((val) => (val === 'None' ? val : Number(val)))
      : [],
  });
  const selectedTab = tabData.findIndex((item) => item === tab);
  const [currentTab, setTab] = React.useState<number>(selectedTab);
  const [isAllCustomerSelected, setIsAllCustomerSelected] = React.useState(false);
  const [selectedCustomers, setSelectedCustomers] = React.useState<number[]>([]);
  const [isExportCustomersModalOpen, setIsExportCustomersModalOpen] = React.useState(false);
  const [isAddCustomersModalOpen, setIsAddCustomersModalOpen] = React.useState(false);
  const [isResolveAlertsModalOpen, setIsResolveAlertsModalOpen] = React.useState(false);
  const [isAddToCaseModalOpen, setIsAddToCaseModalOpen] = React.useState(false);
  const [closedStage, setClosedStage] = React.useState<IStageListLite>();
  const [disableAllTabs, setDisableAllTabs] = React.useState(true);
  const selectedDropdownOptions = [
    {
      label: 'Resolve open alerts',
      value: 'resolve',
      iconStart: <CheckCircle size={17} />,
    },
    {
      label: 'Add to Case',
      value: 'case',
      iconStart: <FolderPlus size={17} />,
    },
  ];

  const stagesListLiteQuery = useStagesListLiteGet();

  const customerQueryAll = useCustomerGetCustomerList({ offset: 0, filters });

  const customerQueryAssigned = useCustomerGetCustomerList({
    offset: 0,
    filters,
    assigned_to: state.userProfile?.id,
  });

  const customerQueryUnassigned = useCustomerGetCustomerList({ offset: 0, filters, assigned_to: 'None' });

  const customerQueryClosed = useCustomerGetCustomerList(
    { offset: 0, filters, workflow_stage: closedStage?.id, closed: true },
    { enabled: !!closedStage }
  );
  const getAssignmentCountOptions = (workspaces: IGetWorkspaceAssignmentCountsResponse) => {
    if (workspaces?.total === 0) {
      return [];
    }
    return workspaces?.workspaces?.map((r) => {
      return {
        label: r.name,
        value: r.slug,
        iconEnd: (
          <div className='ml-2 flex items-center gap-2'>
            <div className='whitespace-nowrap'>{`${r.count} assigned ${
              r.count > 1 ? 'customers' : 'customer'
            }`}</div>
            <div className='rounded border border-gray-200 p-1'>
              <ArrowSquareOut size={22} />
            </div>
          </div>
        ),
      };
    });
  };

  const workspaceAssignmentCount = useWorkspaceAssignmentCountGet({ type: 'customers' });
  const crossWorkspaceOptions = getAssignmentCountOptions(workspaceAssignmentCount?.data?.data);

  const isLoading =
    customerQueryAll.isLoading &&
    customerQueryAssigned.isLoading &&
    customerQueryUnassigned.isLoading &&
    customerQueryClosed.isLoading &&
    stagesListLiteQuery.isLoading;

  const [allCustomersCount, allCustomersData] = flattenInfiniteQueryResult(customerQueryAll?.data);
  const [assignedCustomersCount, assignedCustomersData] = flattenInfiniteQueryResult(
    customerQueryAssigned?.data
  );
  const [unAssignedAdressesCount, unAssignedCustomersData] = flattenInfiniteQueryResult(
    customerQueryUnassigned?.data
  );
  const [closedCustomersCount, closedCustomersData] = flattenInfiniteQueryResult(customerQueryClosed?.data);

  const tabs = [
    { label: 'Assigned to me', count: assignedCustomersCount, slug: 'assigned_to_me' },
    {
      label: 'Unassigned',
      count: unAssignedAdressesCount,
      slug: 'unassigned',
    },
    {
      label: 'Closed',
      count: stagesListLiteQuery?.data?.data?.count && closedCustomersCount,
      slug: 'closed',
    },
    {
      label: 'All Customers',
      count: allCustomersCount,
      slug: 'all_customers',
    },
  ];

  function getCustomerData(tab: number) {
    //TODO: need to add a interface for selectedQuery
    let customers: ICustomerResponse[], count: number, selectedQuery: any;

    switch (tab) {
      case 0:
        customers = assignedCustomersData;
        count = assignedCustomersCount;
        selectedQuery = customerQueryAssigned;
        break;
      case 1:
        customers = unAssignedCustomersData;
        count = unAssignedAdressesCount;
        selectedQuery = customerQueryUnassigned;
        break;
      case 2:
        customers = closedCustomersData;
        count = closedCustomersCount;
        selectedQuery = customerQueryClosed;
        break;
      default:
        customers = allCustomersData;
        count = allCustomersCount;
        selectedQuery = customerQueryAll;
    }

    return { customers, count, selectedQuery };
  }

  const { customers, count, selectedQuery } = getCustomerData(currentTab);

  React.useEffect(() => {
    setClosedStage(stagesListLiteQuery?.data?.data.results.find((item) => item.is_final_stage));
  }, [stagesListLiteQuery]);

  React.useEffect(() => {
    if (selectedTab < 0 && assignedCustomersCount !== 0 && !customerQueryAssigned.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultCustomerListFilters;
      setFilters(localStorageFilters);
      onChange(0, localStorageFilters);
    } else if (assignedCustomersCount === 0 && currentTab === -1 && !customerQueryAssigned.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultCustomerListFilters;
      setFilters(localStorageFilters);
      onChange(3, localStorageFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerQueryAssigned.isLoading]);

  React.useEffect(() => {
    const localStorageFilters = filterStorage
      ? formatLocalStorageValue(filterStorage)
      : defaultCustomerListFilters;

    workspace.workspaces.forEach((w) => {
      if (!localStorage.getItem(`compass.storage.customerFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.customerFilters.${w.slug}`,
          JSON.stringify(defaultCustomerListFilters)
        );
      }
    });

    if (isEqual(filters, defaultCustomerListFilters)) {
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters, currentTab);
    } else {
      setFilterStorage(filters);
      setDisableAllTabs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      if (filterStorage) {
        setFilters(filterStorage);
        Object.keys(filterStorage).forEach((key) => {
          if (
            filterStorage[key] !== null &&
            (!Array.isArray(filterStorage[key]) || filterStorage[key].length !== 0)
          ) {
            newSearchParams.set(key, filterStorage[key].toString());
          }
        });
      }
      setTimeout(() => {
        const { tab } = getQueryParams();
        if (tab) newSearchParams.set('tab', tab);
        navigate(`/customers`, Object.fromEntries(newSearchParams));
      }, 100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStorage]);

  const syncFilters = (newFilters: IFilters, tab_id?: number) => {
    if (state?.userProfile.email) {
      if (tab_id !== -1 || tab_id == null) {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('tab', tabs[tab_id || tab_id === 0 ? tab_id : currentTab ? currentTab : 0]?.slug);
        Object.keys(newFilters).forEach((key) => {
          if (newFilters[key] !== null && (!Array.isArray(newFilters[key]) || newFilters[key].length !== 0)) {
            newSearchParams.set(key, newFilters[key].toString());
          }
        });
        setFilterStorage(newFilters);
        navigate(`/customers`, Object.fromEntries(newSearchParams));
        setDisableAllTabs(false);
      }
    }
  };

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach((key) => {
      if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
    syncFilters(newFilters);
  };

  const onReset = () => {
    setFilters(defaultCustomerListFilters);
    syncFilters(defaultCustomerListFilters);
  };

  const selectCustomer = (id) => {
    if (selectedCustomers?.includes(id)) {
      setSelectedCustomers(selectedCustomers.filter((i) => i !== id));
    } else {
      setSelectedCustomers([...selectedCustomers, id]);
    }
  };

  const selectAllCustomers = (force = false) => {
    if (force) {
      setSelectedCustomers(customers.map((customer) => customer.id));
    } else {
      if (selectedCustomers.length === customers.length) {
        setSelectedCustomers([]);
        setIsAllCustomerSelected(false);
      } else {
        setSelectedCustomers(customers.map((customer) => customer.id));
      }
    }
  };

  const downloadSelectedCustomers = async () => {
    if (isAllCustomerSelected) {
      if (selectedTab === 3) {
        const res = await customerApi.exportCustomerList({
          ids: isAllCustomerSelected ? [] : selectedCustomers,
          filters,
        });
        toast.success(res.data.message);
      } else if (selectedTab === 0) {
        const res = await customerApi.exportCustomerList({
          ids: isAllCustomerSelected ? [] : selectedCustomers,
          filters,
          assigned_to: state.userProfile?.id,
        });
        toast.success(res.data.message);
      } else if (selectedTab === 1) {
        const res = await customerApi.exportCustomerList({
          ids: isAllCustomerSelected ? [] : selectedCustomers,
          filters,
          assigned_to: 'None',
        });
        toast.success(res.data.message);
      } else if (selectedTab === 2) {
        const res = await customerApi.exportCustomerList({
          ids: isAllCustomerSelected ? [] : selectedCustomers,
          filters,
          workflow_stage: closedStage?.id,
          closed: true,
        });
        toast.success(res.data.message);
      }
    } else {
      const res = await customerApi.exportCustomerList({
        ids: selectedCustomers,
      });
      toast.success(res.data.message);
    }
  };

  const onChange = (tab: number, newFilters?: IFilters) => {
    setTab(tab);
    setSelectedCustomers([]);
    setIsAllCustomerSelected(false);
    if (newFilters) {
      syncFilters(newFilters, tab);
    } else {
      syncFilters(filters, tab);
    }
  };

  const onChangeSelectedDropdown = (value: DropdownOption) => {
    if (value.value === 'resolve') {
      setIsResolveAlertsModalOpen(true);
    } else if (value.value === 'case') {
      setIsAddToCaseModalOpen(true);
    }
  };

  const onChangeWorkspacesDropdown = (value: DropdownOption) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.search = '';
    currentUrl.searchParams.set('workspace', value.value.toString());
    window.open(currentUrl.toString(), '_blank');
  };

  const customerData = useGetCustomerData({
    selectAllCustomers,
    selectedCustomers,
    customers,
    selectCustomer,
  });

  const headerData: (string | JSX.Element)[] = customerData.headerData;
  const rows: ITableRow[] = customerData.rowData;

  return (
    <>
      <Table
        title='Customers'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${count ?? 0} results`}
        isLoading={isLoading}
        tab={tab}
        headerActionsLeft={
          <Tab
            values={tabs}
            defaultIndex={currentTab ?? null}
            type='primary'
            listClassName='rounded-t-md'
            inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
            panelClassName='px-4'
            isLoading={isLoading}
            onChange={onChange}
            changeTab={currentTab ?? null}
            disableAllTabs={disableAllTabs}
          />
        }
        filterComponent={
          <>
            {workspaceAssignmentCount?.data?.data.total !== 0 && !workspaceAssignmentCount.isLoading && (
              <SimpleDropdown
                value={null}
                onChange={onChangeWorkspacesDropdown}
                placeholder={
                  <Button variant='tertiary' className='m-1 px-3'>
                    <div className='pr-2 text-2xs font-medium text-gray-800'>
                      {`${
                        workspaceAssignmentCount?.data?.data.total
                          ? workspaceAssignmentCount?.data?.data.total === 1
                            ? `${workspaceAssignmentCount?.data?.data.total} Cross-Workspace Customer`
                            : `${workspaceAssignmentCount?.data?.data.total} Cross-Workspace Customers`
                          : '0 Cross-Workspace Customer'
                      }`}
                    </div>
                    <CaretDown weight='bold' fill='fill' size={16} className={classNames('mr-0.5')} />
                  </Button>
                }
                options={crossWorkspaceOptions}
              />
            )}
            {selectedCustomers.length > 0 && (
              <SimpleDropdown
                value={null}
                onChange={onChangeSelectedDropdown}
                placeholder={
                  <Button variant='tertiary' className='my-1 px-3'>
                    <DotsThreeOutlineVertical size={16} weight='fill' />
                  </Button>
                }
                options={selectedDropdownOptions}
              />
            )}
            {selectedCustomers.length === 0 && (
              <Button
                variant='tertiary'
                iconStart={<Download size={17} />}
                onClick={() => setIsExportCustomersModalOpen(true)}>
                Export
              </Button>
            )}
            <CustomerListFilter
              filters={filters}
              onApply={handleApply}
              onReset={onReset}
              disabled={selectedCustomers.length > 0}
              tab={currentTab}
            />
            <Button
              iconStart={<Plus size={17} />}
              onClick={() => setIsAddCustomersModalOpen(true)}
              disabled={selectedCustomers.length > 0}>
              Add Customer
            </Button>
          </>
        }
        appliedFilters={
          <CustomerListAppliedFilter
            count={count}
            filters={filters}
            setFilters={handleApply}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            isAllCustomerSelected={isAllCustomerSelected}
            setIsAllCustomerSelected={setIsAllCustomerSelected}
            selectAllCustomers={selectAllCustomers}
            downloadSelectedCustomers={downloadSelectedCustomers}
            stageOptions={stagesListLiteQuery?.data?.data?.results}
            tab={currentTab}
            selectedText={
              isAllCustomerSelected
                ? `${count} customers selected.`
                : selectedCustomers.length === customers.length
                  ? `All ${selectedCustomers.length} customers in this page are selected.`
                  : `${selectedCustomers.length} ${
                      selectedCustomers.length > 1 ? 'customers' : 'customer'
                    } selected.`
            }
          />
        }
        heightOffset={25.5}
        isSelectable
        {...buildInfiniteQueryTableProps(selectedQuery)}
      />

      <CustomerListExportModal
        isOpen={isExportCustomersModalOpen}
        onClose={() => setIsExportCustomersModalOpen(false)}
        downloadSelectedCustomers={downloadSelectedCustomers}
      />
      <AddCustomerModal isOpen={isAddCustomersModalOpen} onClose={() => setIsAddCustomersModalOpen(false)} />
      <ResolveMultipleAlertsModal
        type='customer'
        isOpen={isResolveAlertsModalOpen}
        onClose={() => setIsResolveAlertsModalOpen(false)}
        selectedEntities={selectedCustomers}
        isAllEntitiesSelected={isAllCustomerSelected}
        refetchList={() => selectedQuery.refetch()}
        selectedTab={selectedTab}
        filters={filters}
        closedStage={closedStage}
      />
      <AddToCaseModal
        type='customer'
        isOpen={isAddToCaseModalOpen}
        onClose={() => setIsAddToCaseModalOpen(false)}
        entities={selectedCustomers.map((customer) => {
          let data;
          customers.forEach((add) => {
            if (add.id === customer) {
              data = {
                customer_id: add.customer_id,
              };
            }
          });
          return data;
        })}
        isAllEntitiesSelected={isAllCustomerSelected}
        selectedTab={selectedTab}
        filters={filters}
        closedStage={closedStage}
      />
    </>
  );
};

export default CustomerList;
