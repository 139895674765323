import * as React from 'react';

import { Checkbox } from '../../../ui';
import { Search } from '../../../components/ui/components/Search';

import CalendarFilter, { IDateRangeString } from '../../Filters/CalendarFilter';
import RiskFilter from '../../Filters/RiskFilter';
import CurrencyFilter from '../../Filters/CurrencyFilter';
import RuleNameFilter from '../../Filters/RuleNameFilter';
import { FilterPanel } from '../../FilterPanel';
import StageNameFilter from '../../../components/Filters/StageFilter';

export interface IOption {
  value: string;
  label: string;
}

export interface IFilters {
  deposit_address: string;
  risk_level: number[];
  currencies: number[];
  created_start_date: string;
  created_end_date: string;
  start_date: string;
  end_date: string;
  is_included_in_case: boolean;
  rule_name: string[];
  workflow_stage?: (number | string)[];
}

export const defaultCustomerListFilters: IFilters = {
  deposit_address: null,
  risk_level: [],
  currencies: [],
  created_start_date: null,
  created_end_date: null,
  start_date: null,
  end_date: null,
  is_included_in_case: null,
  rule_name: [],
  workflow_stage: [],
};

interface Props {
  filters: IFilters;
  onApply: (any) => void;
  onReset: () => void;
  disabled?: boolean;
  tab: number;
}

const CustomerListFilter: React.FC<Props> = ({ filters, onApply, disabled, tab }) => {
  const [depositAddress, setDepositAddress] = React.useState('');
  const [riskLevel, setRiskLevel] = React.useState<number[]>([]);
  const [dateAdded, setDateAdded] = React.useState<IDateRangeString>();
  const [dateUpdated, setDateUpdated] = React.useState<IDateRangeString>();
  const [currencies, setCurrencies] = React.useState<number[]>([]);
  const [includedInCase, setIncludedInCase] = React.useState<boolean>(null);
  const [ruleName, setRuleName] = React.useState<string[]>([]);
  const [workflow_stage, setStage] = React.useState<(number | string)[]>([]);

  const handleApply = () => {
    const newFilters: IFilters = {
      deposit_address: depositAddress,
      risk_level: riskLevel,
      currencies: currencies,
      created_start_date: dateAdded.from,
      created_end_date: dateAdded.to,
      start_date: dateUpdated.from,
      end_date: dateUpdated.to,
      is_included_in_case: includedInCase,
      rule_name: ruleName,
      workflow_stage: workflow_stage,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setDepositAddress('');
    setRiskLevel([]);
    setDateAdded(null);
    setDateUpdated(null);
    setCurrencies([]);
    setIncludedInCase(null);
    setRuleName([]);
    setStage([]);
    onApply(defaultCustomerListFilters);
  };

  React.useEffect(() => {
    setDepositAddress(filters.deposit_address);
    setRiskLevel(filters.risk_level);
    setCurrencies(filters.currencies);
    setDateAdded({
      from: filters.created_start_date,
      to: filters.created_end_date,
    });
    setDateUpdated({
      from: filters.start_date,
      to: filters.end_date,
    });
    setIncludedInCase(filters.is_included_in_case);
    setRuleName(filters.rule_name);
    setStage(filters.workflow_stage);
  }, [filters]);

  return (
    <FilterPanel tab={tab} onApply={handleApply} onReset={onReset} disabled={disabled}>
      <div />
      <div className='relative'>
        <Search
          placeholder='Search using address hash'
          label='Filter'
          setValue={setDepositAddress}
          value={depositAddress}
          onKeyDown={(e) => e.key === 'Enter' && handleApply()}
        />
        {depositAddress?.length ? (
          <div className='absolute left-0 top-full z-max w-full rounded-md bg-white p-3 text-center text-sm shadow-md'>
            Press enter after typing the Address Hash
          </div>
        ) : null}
      </div>
      <RiskFilter value={riskLevel} setValue={setRiskLevel} />
      {tab !== 2 ? <StageNameFilter value={workflow_stage} setValue={setStage} /> : <></>}
      <CurrencyFilter value={currencies} setValue={setCurrencies} />
      <CalendarFilter value={dateAdded} setValue={setDateAdded} label='Date Added' />
      <CalendarFilter value={dateUpdated} setValue={setDateUpdated} label='Date Updated' />
      <Checkbox
        checked={includedInCase}
        labelText={<span className='text-sm font-medium leading-5 text-gray-700'>Included in Case</span>}
        onChange={(e) => setIncludedInCase((e.target as HTMLInputElement).checked)}
      />
      <RuleNameFilter value={ruleName} setValue={setRuleName} type={'customer'} />
    </FilterPanel>
  );
};

export default CustomerListFilter;
