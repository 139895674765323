/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useAlertGetAllAlerts } from '../../../api/alert';
import AlertListFilter, {
  defaultAlertListFilters,
  IFilters,
} from '../../../components/AlertList/AlertListFilter';
import { useRouter } from '../../../modules/router/RouterProvider';
import { Button, Checkbox, RiskBadge } from '../../../ui';
import AlertStatusBadge from '../../../ui/components/Badges/AlertBadge/AlertStatusBadge';
import Table from '../../../ui/components/Table/Table';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import AlertListAppliedFilter from '../../../components/AlertList/AlertListAppliedFilter';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import CurrencyBadge from '../../../components/ui/components/Badge/CurrencyBadge';
import { isArray, startCase } from 'lodash';
import Tab from '../../../components/ui/components/Tab/Tab';
import { ellipsis, getNextPageParam } from '../../../utils/helpers/helperFunctions';
import Popover from '../../../ui/components/Popover/Popover';
import { Tooltip } from '../../../ui/components/Tooltip';
import CustomerAddressList from '../../../components/Address/AddressList/CustomerAddressList';
import { Date } from '../../../components/ui/components/Date';
import { useAuth } from '../../../modules/auth';
import { Search } from '../../../components/ui/components/Search';
import { useEffectOnce, useLocalStorage } from 'react-use';
import { useWorkspace } from '../../../utils/helpers/common';
import { isEqual } from 'lodash';
import { CheckCircle } from '@phosphor-icons/react';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';
import { toast } from 'react-toastify';
import { useInfiniteQuery } from 'react-query';
import { classifierApi } from '../../../api/classifier';

const AlertList = () => {
  const screenApi = useScreenApi();
  const { navigate, getQueryParams } = useRouter();
  // const { tab  } = getQueryParams();
  const { level, start_time, end_time, rule_id, rule_name, policy_type, policy_category, tab } =
    getQueryParams();
  const workspace = useWorkspace();

  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.alertFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = useState<IFilters>({
    level: level ? level.split(',').map(Number) : [],
    rule_id: rule_id && rule_id !== 'null' ? rule_id : null,
    rule_name: rule_name && rule_name !== 'null' ? rule_name : null,
    start_time: start_time && start_time !== 'null' ? start_time : null,
    end_time: end_time && end_time !== 'null' ? end_time : null,
    policy_category: policy_category && policy_category !== 'null' ? policy_category : null,
    policy_type: policy_type && policy_type !== 'null' ? Number(policy_type) : null,
  });
  const selectedTab = tab ? parseInt(tab) : -1;
  const [selectedAlerts, setSelectedAlerts] = useState<number[]>([]);
  const [isAllAlertsSelected, setIsAllAlertsSelected] = useState(false);
  const [search, setSearch] = useState('');
  const [prevWorkspaceSlug, setPrevWorkspaceSlug] = useState(workspace.slug);
  const [currentTab, setCurrentTab] = useState(Number(tab) ? parseInt(tab) : 0);
  const [disableAllTabs, setDisableAllTabs] = useState(true);
  const [isResolveAlertsModalOpen, setIsResolveAlertsModalOpen] = useState(false);
  const { state } = useAuth();
  const [isQueryEnabled, setIsQueryEnabled] = useState({
    open: false,
    escalated: false,
    resolved: false,
    stale: false,
    all: false,
  });
  const openAlertsQuery = useAlertGetAllAlerts(
    { ...filters, status: 0, is_stale: false, q: search },
    { enabled: isQueryEnabled.open || currentTab === 0 }
  );
  const escalatedAlertsQuery = useAlertGetAllAlerts(
    { ...filters, status: 2, is_stale: false, q: search },
    {
      enabled: isQueryEnabled.escalated || currentTab === 1,
    }
  );
  const resolvedAlertsQuery = useAlertGetAllAlerts(
    { ...filters, status: 1, is_stale: false, q: search },
    { enabled: isQueryEnabled.resolved || currentTab === 2 }
  );
  const staleAlertsQuery = useAlertGetAllAlerts(
    { ...filters, is_stale: true, q: search },
    {
      enabled: isQueryEnabled.stale || currentTab === 3,
    }
  );
  const allAlertsQuery = useAlertGetAllAlerts(
    { ...filters, q: search },
    {
      enabled: isQueryEnabled.all || currentTab === 4,
    }
  );
  const ruleNamesQuery = useInfiniteQuery(
    ['ruleName', 'all'],
    ({ pageParam = 0 }) => classifierApi.getClassifiers({ rule_type: null, limit: 500, offset: pageParam }),
    {
      getNextPageParam,
    } as unknown
  );

  useEffect(() => {
    if (openAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, escalated: true }));
    }
  }, [openAlertsQuery.isFetched]);

  useEffect(() => {
    if (escalatedAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, resolved: true }));
    }
  }, [escalatedAlertsQuery.isFetched]);

  useEffect(() => {
    if (resolvedAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, stale: true }));
    }
  }, [resolvedAlertsQuery.isFetched]);

  useEffect(() => {
    if (staleAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, all: true }));
    }
  }, [staleAlertsQuery.isFetched]);

  useEffect(() => {
    if (allAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, open: true }));
    }
  }, [allAlertsQuery.isFetched]);

  const [allAlertsCount, allAlertsData] = flattenInfiniteQueryResult(allAlertsQuery?.data);
  const [openAlertsCount, openAlertsData] = flattenInfiniteQueryResult(openAlertsQuery?.data);
  const [resolvedAlertsCount, resolvedAlertsData] = flattenInfiniteQueryResult(resolvedAlertsQuery?.data);
  const [escalatedAlertsCount, escalatedAlertsData] = flattenInfiniteQueryResult(escalatedAlertsQuery?.data);
  const [staleAlertsCount, staleAlertsData] = flattenInfiniteQueryResult(staleAlertsQuery?.data);

  const alertsQuery =
    currentTab < 0
      ? openAlertsQuery
      : [openAlertsQuery, escalatedAlertsQuery, resolvedAlertsQuery, staleAlertsQuery, allAlertsQuery][
          currentTab
        ];
  const alertsCount =
    currentTab < 0
      ? openAlertsCount
      : [openAlertsCount, escalatedAlertsCount, resolvedAlertsCount, staleAlertsCount, allAlertsCount][
          currentTab
        ];
  const alertsData =
    currentTab < 0
      ? openAlertsData
      : [openAlertsData, escalatedAlertsData, resolvedAlertsData, staleAlertsData, allAlertsData][currentTab];

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach((key) => {
      if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const syncFilters = (newFilters: IFilters, tab_id?: number) => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      const tabValue = tab_id !== undefined && tab_id !== null ? tab_id.toString() : currentTab?.toString();
      newSearchParams.set('tab', tabValue);
      Object.keys(newFilters).forEach((key) => {
        if (newFilters[key] !== null && (!Array.isArray(newFilters[key]) || newFilters[key].length !== 0)) {
          newSearchParams.set(key, newFilters[key].toString());
        }
      });
      setFilterStorage(newFilters);
      navigate(`/alerts`, Object.fromEntries(newSearchParams));
      setDisableAllTabs(false);
    }
  };

  useEffectOnce(() => {
    workspace.workspaces.forEach((w) => {
      if (!localStorage.getItem(`compass.storage.alertFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.alertFilters.${w.slug}`,
          JSON.stringify(defaultAlertListFilters)
        );
      }
    });
  });

  // set filters on url priority
  useEffect(() => {
    if (isEqual(defaultAlertListFilters, filters)) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultAlertListFilters;
      if (localStorageFilters.rule_id === undefined || isArray(localStorageFilters.rule_name)) {
        localStorageFilters.rule_id = null;
        localStorageFilters.rule_name = null;
      }
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters);
    } else {
      setFilterStorage(filters);
      setDisableAllTabs(false);
    }
  }, []);

  // reset filters when switching workspace
  useEffect(() => {
    if (prevWorkspaceSlug !== workspace.slug && workspace.slug) {
      setPrevWorkspaceSlug(workspace.slug);
      setFilters(defaultAlertListFilters);
    }
  }, [workspace.slug]);

  // switch to all tab if open alerts count is 0
  useEffect(() => {
    if (openAlertsQuery.isSuccess && openAlertsCount === 0) {
      onChangeTab(4, filters);
    }
  }, [openAlertsQuery.isSuccess]);

  const headerData = [
    ...([-1, 0, 1, 4].includes(currentTab) && [0, 1, 4].includes(selectedTab)
      ? [
          <Checkbox
            key={0}
            className='w-0 rounded'
            onChange={() => selectAllAlerts()}
            checked={selectedAlerts.length === alertsData.length && alertsData.length !== 0}
          />,
        ]
      : []),
    'Alert ID',
    <Popover
      key={0}
      isBasic
      referenceContent='Identifier'
      popoverContent='Identifier of Address/Transaction or Customer id'
    />,
    'Blockchain',
    'Customer ID',
    'Policy Type & Category',
    'Rule Name',
    <>
      Last Created at
      {/* <Tooltip className='z-99 bg-slate-300 text-gray-900' content='' id='last-created-at-label' label='i' /> */}
    </>,
    'Risk level',
    'Status',
  ];

  const rows = alertsData?.map((alert) => {
    const entity = alert.content_object_fields;
    return {
      id: alert.id,
      data: [
        ...([-1, 0, 1, 4].includes(currentTab) && [0, 1, 4].includes(selectedTab)
          ? [
              <Checkbox
                key={alert.id}
                className='w-0 rounded'
                onChange={(e) => {
                  e.stopPropagation();
                  selectAlert(alert.id);
                }}
                checked={selectedAlerts.includes(alert.id)}
              />,
            ]
          : []),
        alert.id,
        <IdentifierEllipsis
          copyable
          key={alert.id}
          clickable
          identifier={entity.identifier || entity.customer_id}
        />,
        <CurrencyBadge key={alert.id} currency={entity.currency} />,
        alert.entity_type !== 'customer' ? <CustomerAddressList key={alert.id} address={entity} /> : null,
        startCase(alert.entity_type) + ' > ' + alert.rule_register.policy_category_verbose,
        <Popover
          key={alert.id}
          isBasic
          referenceContent={ellipsis(alert.rule_register.rule_name || '', 25)}
          popoverContent={alert.rule_register.rule_name}
        />,
        <Date key={alert.id} date={alert.opened_at} type='duration' tz={state.userProfile.timezone} />,
        <RiskBadge key={alert.id} risk={alert.level} grayScale={alert.is_stale || alert.status === 1} />,
        <AlertStatusBadge key={alert.id} type={!alert.is_stale ? alert.status : 3} hideStatusLabel />,
      ],
      onClick: async () => {
        if (alert.entity_type === 'customer') {
          navigate(`/customers/${entity.customer_id}`, { alert: alert.id.toString() });
          return;
        }
        const entityLong = alert.entity_type === 'address' ? 'addresses' : 'transactions';
        const result = await screenApi(
          {
            identifier: entity.identifier,
            currency: entity.currency,
            entityType: entityLong,
            customer_id: entity.customer_id,
            type: entity.type,
            address: entity.deposit_address,
          },
          false
        );
        navigate(`/${entityLong}/${result.id}`, { alert: alert.id.toString() });
      },
    };
  });

  const tabs = [
    {
      label: 'Open',
      count: openAlertsCount,
    },
    {
      label: 'Escalated',
      count: escalatedAlertsCount,
    },
    {
      label: 'Resolved',
      count: resolvedAlertsCount,
    },
    {
      label: (
        <div>
          <Tooltip
            place='bottom'
            className='-ml-1 mr-1 bg-slate-300/100 leading-3 text-gray-900'
            content='Inactive alerts are alerts created in the past but are no longer active due to reasons indicated in the audit trail for the respective alert'
            id='inactive-alert-tooltip'
            label='i'
          />
          Inactive
        </div>
      ),
      count: staleAlertsCount,
    },
    {
      label: 'All',
      count: allAlertsCount,
    },
  ];

  const selectAlert = (id) => {
    if (selectedAlerts?.includes(id)) {
      setSelectedAlerts(selectedAlerts.filter((i) => i !== id));
    } else {
      setSelectedAlerts([...selectedAlerts, id]);
    }
  };

  const selectAllAlerts = (force = false) => {
    if (force) {
      setSelectedAlerts(alertsData.map((alert) => alert.id));
    } else {
      if (selectedAlerts.length === alertsData.length) {
        setSelectedAlerts([]);
        setIsAllAlertsSelected(false);
      } else {
        setSelectedAlerts(alertsData.map((alert) => alert.id));
      }
    }
  };

  const disableAllQueries = () => {
    setIsQueryEnabled({
      open: false,
      escalated: false,
      resolved: false,
      stale: false,
      all: false,
    });
  };

  const onApply = (filters) => {
    setFilters(filters);
    syncFilters(filters, currentTab);
    disableAllQueries();
  };

  const onReset = () => {
    setFilters(defaultAlertListFilters);
    syncFilters(defaultAlertListFilters, currentTab);
    disableAllQueries();
  };

  const onChangeTab = (index: number, newFilters?: IFilters) => {
    setCurrentTab(index);
    setSelectedAlerts([]);
    setIsAllAlertsSelected(false);
    if (newFilters) {
      syncFilters(newFilters, index);
    } else {
      syncFilters(filters, index);
    }
  };

  return (
    <div>
      <Table
        title='Alerts'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${alertsCount ?? 0} results`}
        heightOffset={26.5}
        tab={tab}
        headerActionsLeft={
          <Tab
            values={tabs}
            onChange={onChangeTab}
            type={'primary'}
            defaultIndex={currentTab}
            showCountLoading
            disableAllTabs={disableAllTabs}
            changeTab={currentTab ?? null}
          />
        }
        headerActions={
          <Search
            minChars={3}
            value={search}
            setValue={(q) => {
              setSearch(q);
              disableAllQueries();
            }}
            type='button'
          />
        }
        filterComponent={
          <>
            {selectedAlerts.length > 0 && (
              <Button
                variant='tertiary'
                iconStart={<CheckCircle size={17} />}
                onClick={() => {
                  if (selectedAlerts.length > 100 || (isAllAlertsSelected && alertsCount > 100)) {
                    toast.error('You can resolve a maximum of 100 alerts at a time');
                  } else {
                    setIsResolveAlertsModalOpen(true);
                  }
                }}>
                Resolve Alerts
              </Button>
            )}

            <AlertListFilter
              filters={filters}
              onApply={onApply}
              onReset={onReset}
              ruleNamesQuery={ruleNamesQuery}
              disabled={selectedAlerts?.length > 0}
            />
          </>
        }
        appliedFilters={
          <AlertListAppliedFilter
            count={alertsCount}
            filters={filters}
            setFilters={onApply}
            selectedAlerts={selectedAlerts}
            setSelectedAlerts={setSelectedAlerts}
            isAllAlertsSelected={isAllAlertsSelected}
            setIsAllAlertsSelected={setIsAllAlertsSelected}
            selectAllAlerts={selectAllAlerts}
            selectedText={`${selectedAlerts.length} ${selectedAlerts.length > 1 ? 'alerts' : 'alert'} selected.`}
          />
        }
        {...buildInfiniteQueryTableProps(alertsQuery)}
        isLoading={!alertsQuery.isFetched && !alertsQuery.isFetchingNextPage}
        isSelectable
      />
      <ResolveMultipleAlertsModal
        type='alert'
        isOpen={isResolveAlertsModalOpen}
        onClose={() => setIsResolveAlertsModalOpen(false)}
        selectedEntities={selectedAlerts}
        isAllEntitiesSelected={isAllAlertsSelected}
        refetchList={() => {
          setSelectedAlerts([]);
          setIsAllAlertsSelected(false);
          alertsQuery.refetch();
          resolvedAlertsQuery.refetch();
        }}
        selectedTab={selectedTab}
        filters={filters}
        searchText={search}
      />
    </div>
  );
};

export default AlertList;
